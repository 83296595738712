// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../assets/images/logo_aval.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root {\r\n  --companyName: \"Grupo Aval\";\r\n  --companyLogo: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n\r\n  --primary: #0067ac;\r\n  --secondary: #003464;\r\n  --third: #979797;\r\n\r\n  --white: #fff;\r\n  --black: #000;\r\n\r\n  --banner-bg: linear-gradient(180deg, var(--secondary) 10%, var(--primary) 100%);\r\n\r\n  --login-client-color: var(--primary);\r\n  --login-product-color: var(--secondary);\r\n  --login-button-color: var(--primary);\r\n\r\n  --header-bg: var(--white);\r\n  --header-color: var(--secondary);\r\n  --header-menu-color: var(--secondary);\r\n  --header-color-ph: #c5cecdad;\r\n\r\n  --store-title-color: var(--primary);\r\n  --store-icons-color: var(--secondary);\r\n\r\n  --footer-bg: var(--white);\r\n  --footer-color: var(--third);\r\n}", "",{"version":3,"sources":["webpack://./src/themes/theme_aval_.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,sDAAoD;;EAEpD,kBAAkB;EAClB,oBAAoB;EACpB,gBAAgB;;EAEhB,aAAa;EACb,aAAa;;EAEb,+EAA+E;;EAE/E,oCAAoC;EACpC,uCAAuC;EACvC,oCAAoC;;EAEpC,yBAAyB;EACzB,gCAAgC;EAChC,qCAAqC;EACrC,4BAA4B;;EAE5B,mCAAmC;EACnC,qCAAqC;;EAErC,yBAAyB;EACzB,4BAA4B;AAC9B","sourcesContent":[":root {\r\n  --companyName: \"Grupo Aval\";\r\n  --companyLogo: url('../assets/images/logo_aval.png');\r\n\r\n  --primary: #0067ac;\r\n  --secondary: #003464;\r\n  --third: #979797;\r\n\r\n  --white: #fff;\r\n  --black: #000;\r\n\r\n  --banner-bg: linear-gradient(180deg, var(--secondary) 10%, var(--primary) 100%);\r\n\r\n  --login-client-color: var(--primary);\r\n  --login-product-color: var(--secondary);\r\n  --login-button-color: var(--primary);\r\n\r\n  --header-bg: var(--white);\r\n  --header-color: var(--secondary);\r\n  --header-menu-color: var(--secondary);\r\n  --header-color-ph: #c5cecdad;\r\n\r\n  --store-title-color: var(--primary);\r\n  --store-icons-color: var(--secondary);\r\n\r\n  --footer-bg: var(--white);\r\n  --footer-color: var(--third);\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
