// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../assets/images/logo_porvenir.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root {\n    --companyName: \"Porvenir\";\n    --companyLogo: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n\n    --primary: #f2b63c;\n    --secondary: #e56649;\n    --third: #ff9933;\n\n    --white: #fff;\n    --black: #000;\n\n    --banner-bg: linear-gradient(180deg, #6a8635 0%, #9fc160 100%);\n\n    --login-client-color: var(--secondary);\n    --login-product-color: #87ab43;\n    --login-button-color: var(--primary);\n\n    --header-bg: var(--primary);\n    --header-color: var(--white);\n    --header-menu-color: var(--white);\n    --header-color-ph: #c5cecdad;\n\n    --store-title-color: #87ab43;\n    --store-icons-color: #87ab43;\n\n    --footer-bg: var(--white);\n    --footer-color: #87ab43;\n}", "",{"version":3,"sources":["webpack://./src/themes/theme_porvenir_.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,sDAAwD;;IAExD,kBAAkB;IAClB,oBAAoB;IACpB,gBAAgB;;IAEhB,aAAa;IACb,aAAa;;IAEb,8DAA8D;;IAE9D,sCAAsC;IACtC,8BAA8B;IAC9B,oCAAoC;;IAEpC,2BAA2B;IAC3B,4BAA4B;IAC5B,iCAAiC;IACjC,4BAA4B;;IAE5B,4BAA4B;IAC5B,4BAA4B;;IAE5B,yBAAyB;IACzB,uBAAuB;AAC3B","sourcesContent":[":root {\n    --companyName: \"Porvenir\";\n    --companyLogo: url('../assets/images/logo_porvenir.svg');\n\n    --primary: #f2b63c;\n    --secondary: #e56649;\n    --third: #ff9933;\n\n    --white: #fff;\n    --black: #000;\n\n    --banner-bg: linear-gradient(180deg, #6a8635 0%, #9fc160 100%);\n\n    --login-client-color: var(--secondary);\n    --login-product-color: #87ab43;\n    --login-button-color: var(--primary);\n\n    --header-bg: var(--primary);\n    --header-color: var(--white);\n    --header-menu-color: var(--white);\n    --header-color-ph: #c5cecdad;\n\n    --store-title-color: #87ab43;\n    --store-icons-color: #87ab43;\n\n    --footer-bg: var(--white);\n    --footer-color: #87ab43;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
