// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../assets/images/logo_ath.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root {\n  --companyName: \"A Toda Hora\";\n  --companyLogo: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n\n  --primary: #0177B5;\n  --secondary: #00356F;\n  --third: #333333;\n\n  --white: #fff;\n  --black: #000;\n\n  --banner-bg: linear-gradient(180deg, var(--primary) 10%, var(--primary) 100%);\n\n  --login-client-color: var(--primary);\n  --login-product-color: var(--primary);\n  --login-button-color: var(--primary);\n\n  --header-bg: var(--primary);\n  --header-color: var(--white);\n  --header-menu-color: var(--white);\n  --header-color-ph: #c5cecdad;\n\n  --store-title-color: var(--primary);\n  --store-icons-color: var(--primary);\n\n  --footer-bg: var(--white);\n  --footer-color: var(--third);\n}", "",{"version":3,"sources":["webpack://./src/themes/theme_ath_.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,sDAAmD;;EAEnD,kBAAkB;EAClB,oBAAoB;EACpB,gBAAgB;;EAEhB,aAAa;EACb,aAAa;;EAEb,6EAA6E;;EAE7E,oCAAoC;EACpC,qCAAqC;EACrC,oCAAoC;;EAEpC,2BAA2B;EAC3B,4BAA4B;EAC5B,iCAAiC;EACjC,4BAA4B;;EAE5B,mCAAmC;EACnC,mCAAmC;;EAEnC,yBAAyB;EACzB,4BAA4B;AAC9B","sourcesContent":[":root {\n  --companyName: \"A Toda Hora\";\n  --companyLogo: url('../assets/images/logo_ath.png');\n\n  --primary: #0177B5;\n  --secondary: #00356F;\n  --third: #333333;\n\n  --white: #fff;\n  --black: #000;\n\n  --banner-bg: linear-gradient(180deg, var(--primary) 10%, var(--primary) 100%);\n\n  --login-client-color: var(--primary);\n  --login-product-color: var(--primary);\n  --login-button-color: var(--primary);\n\n  --header-bg: var(--primary);\n  --header-color: var(--white);\n  --header-menu-color: var(--white);\n  --header-color-ph: #c5cecdad;\n\n  --store-title-color: var(--primary);\n  --store-icons-color: var(--primary);\n\n  --footer-bg: var(--white);\n  --footer-color: var(--third);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
