import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";
import './style.css';
import config from './config/config.json';

let companyTheme;
let customersProperties = [];
let providerLogo = require(`./assets/images/${config.provider.logo}`);

const selectTheme = (themeId) => {
  if (themeId !== '') {
    alert('Esta función no se encuentra activa en esta rama');
  }

  config.themes.forEach(theme => {
    if (config.themeId === theme.id) {
      companyTheme = theme.properties.theme;
    }
    customersProperties.push({
      "value": theme.properties.selector,
      "name": theme.properties.name,
    });
  });
};

selectTheme('');
require(`./themes/${companyTheme}`);

const data: any = {
  props: {
    providerLogo,
    customersProperties,
    selectTheme
  }
};

// Implementación de la función loadWithTimeout
const loadWithTimeout = (name: string, url: string, timeout = 5000): Promise<any> => {
  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      reject(new Error(`Timeout loading ${name}`));
    }, timeout);

    System.import(url).then((module) => {
      clearTimeout(timeoutId);
      resolve(module);
    }).catch((err) => {
      clearTimeout(timeoutId);
      reject(err);
    });
  });
};

// Implementación de la función loadApp
const loadApp = async ({ name }: { name: string }): Promise<any> => {
  console.log(`Loading application: ${name}`);
  const errorTemplateUrl = '/assets/error-template.html'; // Ruta desde el directorio raíz
  try {
    return await System.import(name);
  } catch (err) {
    console.log(`Failed to load ${name}`);
    return Promise.reject(err);
  }
};


const showErrorTemplate = (name: string, errorMessage: string) => {
  const errorTemplateUrl = '/assets/error-template.html'; // Ajusta la ruta según tu configuración
  fetch(errorTemplateUrl)
    .then(response => response.text())
    .then(template => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(template, 'text/html');
      const errorElement = doc.querySelector('.error-details');
      if (errorElement) {
        errorElement.textContent = `Error loading ${name}: ${errorMessage}`;
      }

      const container = document.querySelector('main');
      if (container) {
        container.innerHTML = doc.body.innerHTML;
      }
    })
    .catch(err => {
      console.error('Error loading error template:', err);
    });
};

const routes = constructRoutes(microfrontendLayout, data);
const applications = constructApplications({
  routes,
  loadApp
});

const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);
layoutEngine.activate();
start();

// Manejo global de errores para capturar problemas en la carga de microfrontends
window.addEventListener('unhandledrejection', (event) => {
  console.error('Unhandled promise rejection:', event.reason);
  const errorElement = document.createElement('div');
  errorElement.innerHTML = `Unhandled Error: ${event.reason.message}`;
  errorElement.style.color = 'red'; // Color de error
  document.querySelector('main').appendChild(errorElement);
});
