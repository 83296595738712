// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../assets/images/logo_nttdata_white.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root {\n  --companyName: \"NTT DATA\";\n  --companyLogo: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n\n  --primary: #5472a6;\n  --secondary: #fff;\n  --third: #b08d00;\n\n  --white: #ffffff;\n  --black: #000;\n\n  --banner-bg: linear-gradient(180deg, var(--primary) 10%, var(--secondary) 100%);\n\n  --login-client-color: var(--primary);\n  --login-product-color: var(--secondary);\n  --login-button-color: var(--primary);\n\n  --header-bg: var(--primary);\n  --header-color: var(--white);\n  --header-menu-color: var(--white);\n  --header-color-ph: #c5cecdad;\n\n  --store-title-color: var(--primary);\n  --store-icons-color: var(--third);\n\n  --footer-bg: var(--primary);\n  --footer-color: var(--white);\n}", "",{"version":3,"sources":["webpack://./src/themes/theme_nttdata_.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,sDAA6D;;EAE7D,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;;EAEhB,gBAAgB;EAChB,aAAa;;EAEb,+EAA+E;;EAE/E,oCAAoC;EACpC,uCAAuC;EACvC,oCAAoC;;EAEpC,2BAA2B;EAC3B,4BAA4B;EAC5B,iCAAiC;EACjC,4BAA4B;;EAE5B,mCAAmC;EACnC,iCAAiC;;EAEjC,2BAA2B;EAC3B,4BAA4B;AAC9B","sourcesContent":[":root {\n  --companyName: \"NTT DATA\";\n  --companyLogo: url('../assets/images/logo_nttdata_white.png');\n\n  --primary: #5472a6;\n  --secondary: #fff;\n  --third: #b08d00;\n\n  --white: #ffffff;\n  --black: #000;\n\n  --banner-bg: linear-gradient(180deg, var(--primary) 10%, var(--secondary) 100%);\n\n  --login-client-color: var(--primary);\n  --login-product-color: var(--secondary);\n  --login-button-color: var(--primary);\n\n  --header-bg: var(--primary);\n  --header-color: var(--white);\n  --header-menu-color: var(--white);\n  --header-color-ph: #c5cecdad;\n\n  --store-title-color: var(--primary);\n  --store-icons-color: var(--third);\n\n  --footer-bg: var(--primary);\n  --footer-color: var(--white);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
