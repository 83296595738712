// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../assets/images/logo_ecopetrol.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root {\n    --companyName: \"Ecopetrol\";\n    --companyLogo: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n\n    --primary: #025449;\n    --secondary: #87ab43;\n    --third: #F07B05;\n\n    --white: #fff;\n    --black: #000;\n\n    --banner-bg: linear-gradient(180deg, var(--primary) 0%, var(--secondary) 100%);\n\n    --login-client-color: var(--primary);\n    --login-product-color: var(--secondary);\n    --login-button-color: var(--primary);\n\n    --header-bg: var(--primary);\n    --header-color: var(--white);\n    --header-menu-color: var(--white);\n    --header-color-ph: #c5cecdad;\n\n    --store-title-color: var(--primary);\n    --store-icons-color: var(--primary);\n\n    --footer-bg: var(--primary);\n    --footer-color: var(--white);\n}", "",{"version":3,"sources":["webpack://./src/themes/theme_ecopetrol_.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,sDAAyD;;IAEzD,kBAAkB;IAClB,oBAAoB;IACpB,gBAAgB;;IAEhB,aAAa;IACb,aAAa;;IAEb,8EAA8E;;IAE9E,oCAAoC;IACpC,uCAAuC;IACvC,oCAAoC;;IAEpC,2BAA2B;IAC3B,4BAA4B;IAC5B,iCAAiC;IACjC,4BAA4B;;IAE5B,mCAAmC;IACnC,mCAAmC;;IAEnC,2BAA2B;IAC3B,4BAA4B;AAChC","sourcesContent":[":root {\n    --companyName: \"Ecopetrol\";\n    --companyLogo: url('../assets/images/logo_ecopetrol.png');\n\n    --primary: #025449;\n    --secondary: #87ab43;\n    --third: #F07B05;\n\n    --white: #fff;\n    --black: #000;\n\n    --banner-bg: linear-gradient(180deg, var(--primary) 0%, var(--secondary) 100%);\n\n    --login-client-color: var(--primary);\n    --login-product-color: var(--secondary);\n    --login-button-color: var(--primary);\n\n    --header-bg: var(--primary);\n    --header-color: var(--white);\n    --header-menu-color: var(--white);\n    --header-color-ph: #c5cecdad;\n\n    --store-title-color: var(--primary);\n    --store-icons-color: var(--primary);\n\n    --footer-bg: var(--primary);\n    --footer-color: var(--white);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
